@use "mixins/media-queries" as *;

.grid {
  --of-grid-gap-size: 1rem;

  display: grid;
  grid-gap: var(--of-grid-gap-size);

  // minmax is required to fix a bug in Firefox (css-tricks.com/preventing-a-grid-blowout)
  grid-template-columns: repeat(12, minmax(0, 1fr));

  @include tablet-landscape-only {
    --of-grid-gap-size: 1.5rem;
  }

  @include desktop-and-up {
    --of-grid-gap-size: 2.5rem;
  }
}
