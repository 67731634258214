@use "title-regular" as *;
@use "../mixins/font" as font;
@use "../mixins/media-queries" as *;

.title-1-bold-druk {
  @extend .title-1-regular;
  @include font.bold-druk;
  font-size: 3.25rem;
  text-transform: uppercase;
  line-height: 1.125;
  letter-spacing: -0.02rem;

  @include tablet-portrait-and-up {
    font-size: 3.75rem;
  }

  @include desktop-and-up {
    font-size: 5rem;
  }
}

.title-2-bold-druk {
  @extend .title-2-regular;
  @include font.bold-druk;
  font-size: 2.625rem;
  text-transform: uppercase;
  line-height: 1.125;
  letter-spacing: -0.02rem;

  @include tablet-portrait-and-up {
    font-size: 2.875rem;
  }

  @include desktop-and-up {
    font-size: 3.875rem;
  }
}

.title-3-bold-druk {
  @extend .title-3-regular;
  @include font.bold-druk;
  font-size: 2.25rem;
  line-height: 1;
  letter-spacing: -0.01rem;

  @include tablet-portrait-and-up {
    font-size: 2.375rem;
  }

  @include desktop-and-up {
    font-size: 3rem;
  }
}

.title-4-bold-druk {
  @extend .title-4-regular;
  @include font.bold-druk;
}

.title-5-bold-druk {
  @extend .title-5-regular;
  @include font.bold-druk;
}
