@use "@/styles/mixins/media-queries" as *;
@use "@/styles/mixins/font" as font;
@use "@/styles/mixins/max-root-width";
@use "@/styles/typography/title-bold" as *;
@use "@/styles/typography/text" as *;

.followUsWrapper {
  padding-bottom: 2rem;

  a {
    text-decoration: none;
  }
}

.header {
  @extend .title-5-bold;
  color: var(--label-text-color);
}

.grid {
  display: grid;
  gap: 0.625rem;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 1rem;

  @include tablet-portrait-and-up {
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
  }

  @include desktop-and-up {
    grid-template-columns: repeat(6, 1fr);
    gap: 2.5em;
  }
}

.grid__card {
  background-color: var(--alert-background-color);
  min-width: 0; // this is for stoping grid cells from overflowing the grid
}

.grid__card__link {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: var(--primary-text-color);
  padding: 1rem;

  span {
    @extend .text-5;
    @include text-overflow;
    width: 100%;
    margin-top: 0.35rem;
  }

  .grid__card__link__handle {
    @include font.bold;
  }
}