.wrapper {
  display: flex;
  align-items: center;
  gap: 0.1rem;
}

.yahooLogo {
  position: relative;
  top: 2px;
}
