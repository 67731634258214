@use "@/styles/mixins/media-queries" as *;

.wrapper {
  color: currentColor;
  text-decoration: none;
  padding: 1rem 0;

  @media (hover: hover) {
    &:hover {
      color: currentColor;
    }
  }

  &:visited {
    color: currentColor;
  }

  @include tablet-landscape-and-up {
    position: static;
    transform: unset;
  }
}

.logo {
  display: flex;
  height: 1.25rem;
}
