@use "@/styles/mixins/media-queries" as *;
@use "@/styles/mixins/font" as *;

.root {
  appearance: none;
  border: none;
  border-radius: var(--rounded-corner);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 4.125rem;
  min-width: var(--interactive-element-min-size);
  min-height: var(--interactive-element-min-size);
  line-height: 1.25em;
  text-decoration: none;
  @include medium;
  text-align: center;
  transition: background 250ms ease-in-out, color 250ms ease-in-out,
  box-shadow 250ms ease-in-out, opacity 250ms ease-in-out,
  visibility 250ms ease-in-out, border-color 250ms ease-in-out;
  cursor: pointer;

  background-color: var(--button-bg-color);
  color: var(--button-text-color);
  border: var(--button-border);

  &:active {
    background-color: var(--button-active-bg-color);
    color: var(--button-active-text-color);
    border-color: var(--button-active-border-color);
  }

  @media (hover: hover) {
    &:hover:not(:active) {
      background-color: var(--button-hover-bg-color);
      color: var(--button-hover-text-color);
      box-shadow: var(--button-hover-box-shadow);
      border-color: var(--button-hover-border-color);
    }
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

/**
 * Ghost variant
 */

.ghost {
  --button-bg-color: var(--elevated-background-color);
  --button-text-color: var(--primary-text-color);
  --button-active-bg-color: var(--interaction-unactive-state-color);
  --button-active-text-color: var(--primary-background-color);
  --button-hover-bg-color: var(--primary-text-color);
  --button-hover-text-color: var(--primary-background-color);
  --button-hover-box-shadow: 0 0 1.25rem rgba(64, 62, 74, 0.2);
}


/**
 * Fill variant
 */

.fill {
  --button-bg-color: var(--primary-text-color);
  --button-text-color: var(--primary-background-color);
  --button-active-bg-color: var(--interaction-unactive-state-color);
  --button-active-text-color: var(--primary-background-color);
  --button-hover-bg-color: var(--label-text-color);
  --button-hover-text-color: var(--primary-background-color);
  --button-hover-box-shadow:0 0 1.25em rgba(64, 62, 74, 0.2);
}

/**
 * Outline variant
 */
.outline {
  --button-bg-color: transparent;
  --button-text-color: var(--primary-text-color);
  --button-border: 1px solid var(--divider-color);
  --button-active-bg-color: transparent;
  --button-active-text-color: var(--label-text-color);
  --button-active-border-color: var(--label-text-color);
  --button-hover-bg-color: transparent;
  --button-hover-text-color: var(--primary-text-color);
  --button-hover-border-color: var(--primary-text-color);
}

.ghost,
.fill,
.outline {
  border-radius: var(--rounded-corner, 0.25rem);
  padding: 0.75rem 1.5rem;
}

/**
 * Icon variant
 */
.icon {
  --button-bg-color: var(--elevated-background-color);
  --button-text-color: var(--primary-text-color);
  --button-active-bg-color: var(--interaction-unactive-state-color);
  --button-active-text-color: var(--primary-background-color);
  --button-hover-bg-color: var(--primary-text-color);
  --button-hover-text-color: var(--primary-background-color);
  --button-hover-box-shadow: 0 0 1.25rem rgba(64, 62, 74, 0.2);
}

/**
 * Icon-only variant
 */
.icon-only {
  --button-bg-color: transparent;
  --button-text-color: var(--primary-text-color);
  --button-active-bg-color: transparent;
  --button-active-text-color: var(--interaction-unactive-state-color);
  --button-hover-bg-color: var(--divider-color);
  --button-hover-text-color: var(--primary-background-color);
}

.icon,
.icon-only {
  border-radius: 50%;
  padding: 0;
  --size: var(--interactive-element-min-size);
  width: var(--size);
  height: var(--size);
}

/*
 * Text variant
 */
.text-button {
  --min-size: var(--interactive-element-min-size);
  min-width: var(--min-size);
  min-height: var(--min-size);
  text-decoration: none;
  padding: 0;
  --button-bg-color: var(transparent);
  --button-text-color: var(--label-text-color);
  --button-hover-text-color: var(--primary-text-color);
  transition: color 250ms ease-in-out;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

/*
 * Action variant
 */
.action {
  border-radius: 48px;
  padding: 0.75rem;
  --button-text-color: var(--primary-text-color);
  --button-bg-color: var(--primary-background-color);
  --button-border: 1px solid var(--divider-color);
  --button-active-text-color: var(--primary-text-color);
  --button-active-bg-color: var(--primary-background-color);
  --button-hover-bg-color: var(--alert-background-color);
  --button-hover-text-color: var(--primary-text-color);

  &:disabled {
      cursor: not-allowed;
      opacity: 1; //reset default button values
  }
}

