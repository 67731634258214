@use "@/styles/mixins/elevated-layer" as *;
@use "@/styles/mixins/media-queries" as *;
@use "@/styles/mixins/max-root-width";
@use "@/styles/typography/title-medium" as *;
@use "@/styles/typography/title-bold" as *;

.header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3.4375rem;
  width: 100%;
  transition: filter 200ms ease-in-out;
  @include elevated-layer(var(--header-v3-z-index));
  border-bottom: 1px solid var(--divider-color);

  @include tablet-portrait-and-up {
    position: sticky;
    top: 0;
    height: 5rem;
    justify-content: unset;
  }
}

.mainMenu {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--primary-background-color);
  padding-left: var(--root-side-padding);
  padding-right: var(--root-side-padding);
  @include elevated-layer(var(--header-v3-z-index));
}

.headerScrolled {
  filter: drop-shadow(0 0.3125em 1.25em rgba(0, 0, 0, 0.03));
}

.burgerButton {
  margin-inline-end: 0.875rem;
  flex-shrink: 0;

  @include tablet-landscape-and-up {
    display: none;
  }

  > svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.headerNavWrapper {
  display: flex;
  flex: auto;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;

  @include tablet-landscape-and-up {
    margin-inline-start: 3.125rem;
  }
}

.headerNav {
  display: flex;
  align-items: center;
  align-self: stretch;
  margin: 0;
  padding: 0;
  width: 100%;
  list-style: none;
}

.headerNavListItem {
  @extend .title-7-medium;
  display: flex;
  list-style: none;
  cursor: pointer;
  align-self: stretch;
  align-items: center;
}

.headerNavIconItem,
.headerNavListItem {
  border-bottom: 2px solid transparent;
  transition: border-bottom-color 200ms ease-in-out;

  &:hover,
  &.hovered {
    border-bottom-color: var(--divider-color);
  }

  &.active {
    border-bottom-color: var(--active-element-highlight-color);
    color: var(--active-element-highlight-color);
  }
}

.headerNavIconItem {
  height: 100%;
  display: flex;
  align-items: center;
}

.headerNavIconItem + .headerNavIconItem {
  margin-inline-start: 0.625rem;
}

.desktopNavListItem {
  display: none;

  @include tablet-landscape-and-up {
    display: flex;
  }
}

.headerNavListItem + .headerNavListItem {
  margin-inline-start: 0.625rem;

  @include tablet-portrait-and-up {
    margin-inline-start: 1rem;
  }

  @include tablet-landscape-and-up {
    margin-inline-start: 1.5rem;
  }
}

.headerNavListItem + .headerNavListItemSearch {
  margin-inline-start: auto;
}

.headerNavLink {
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
  padding: 1rem 0;
}

.headerNavListDivider {
  position: relative;
  width: 0.125rem;
  margin: 0 0.625rem;

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    height: 2.5rem;
    width: 100%;
    transform: translate(50%, -50%);
    background-color: var(--divider-color);
  }

  @include tablet-portrait-and-up {
    margin: 0 1rem;

    &::after {
      height: 3rem;
    }
  }

  @include tablet-landscape-and-up {
    margin: 0 1.5rem;
  }
}

.headerNavButtonsContainer {
  display: none;

  @include tablet-portrait-and-up {
    display: flex;

    .headerNavButtonLink {
      margin-inline-start: 1rem;
      padding-inline-start: 1rem;
      padding-inline-end: 1rem;
      white-space: nowrap;
    }
  }

  @include tablet-landscape-and-up {
    .headerNavButtonLink {
      margin-inline-start: 1.5rem;
    }
  }
}

.signInNavListItem {
  @include tablet-portrait-and-up {
    display: none;
  }
}

.headerNavLinkIcon {
  --size: 1rem;
  width: var(--size);
  height: var(--size);

  @include tablet-portrait-and-up {
    --size: 1.5rem;
    width: var(--size);
    height: var(--size);
  }
}

.navListSettingsIcon {
  @include mobile-only {
    display: none;
  }
}

.headerNavButtonButton {
  all: unset;
  height: 100%;
}

.oftvLink {
  margin: 0 0.5rem;
}
