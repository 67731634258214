.snackbar {
  position: fixed;
  left: 50%;
  top: var(--header-height);
  padding-top: 1.5em;
  transform: translateX(-50%);

  z-index: 1000;
}

.wrapper {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr auto auto;
  gap: 0.5em;
  min-width: min(25em, calc(100vw - 2em));
  max-width: 100%;
  align-items: center;
  filter: drop-shadow(0 0.625em 1em rgba(0, 0, 0, 0.08));
  background-color: var(--alert-background-color);

  padding: 1em ;
}

.closed {
  opacity: 0;
  visibility: hidden;
  transition: opacity 300ms ease-in-out, visibility 300ms ease-in-out;
}

.message {
  color: var(--label-text-color);
}

.icon {
}

.iconImage{
  --icon-size: 1.5em;
  width: var(--icon-size);
  height: var(--icon-size);
}

.closeButton {
  --close-button-size: 2.75em ;

  display: grid;
  place-content: center;
  width: var(--close-button-size) ;
  height: var(--close-button-size);
  padding: 0;
  appearance: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: var(--label-text-color);
}

.closeButtonIcon{
  --close-buton-icon-size: 1.75em;

  width: var(--close-buton-icon-size);
  height: var(--close-buton-icon-size);
}


