@use "@/styles/mixins/media-queries" as *;

.adsWrapper {
  display: grid;
  place-items: center;
  overflow-x: auto;
  height: fit-content;
}

.adsWrapperWithBg {
  --padding-size: 2rem;
  padding: var(--padding-size) 0;
  background: var(--elevated-background-color);

  @include mobile-only {
    min-height: calc(var(--mobile-ad-slot-height) + calc(2 * var(--padding-size)));
  }

  @include tablet-portrait-only {
    min-height: calc(var(--tablet-ad-slot-height) + calc(2 * var(--padding-size)));
  }

  @include desktop-and-up {
    min-height: calc(var(--desktop-ad-slot-height) + calc( 2 * var(--padding-size)));
  }
}
