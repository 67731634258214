@use "@/styles/mixins/media-queries" as *;
@use "@/styles/mixins/ellipsis" as *;

.mobileMenu {
  display: flex;
  flex-direction: column;
  padding: 4.25rem 1.25rem 1rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
  background: var(--primary-background-color);
  z-index: var(--page-controls-z-index);
  box-shadow: 0 0.25rem 0.625rem -0.25rem rgba(0, 0, 0, 0.15);

  @include tablet-portrait-and-up {
    padding-top: 6rem;
  }

  @include tablet-landscape-and-up {
    display: none;
  }
}

.subMenuOpened {
  bottom: 0;

  .subMenuButton {
    justify-content: start;
    margin-bottom: 1rem;
    color: var(--label-text-color);
    border: none;
  }

  @include tablet-portrait-and-up {
    height: 52%;
  }
}

.section {
  &:not(:first-of-type) {
    margin-top: 2rem;
  }
}

.linksContainer {
  display: grid;
  margin: 1.25rem 0 0;
  padding-left: 0;
  list-style: none;
  gap: 1.5rem;

  @include tablet-portrait-and-up {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.title {
  color: var(--label-text-color);
}

.subMenuButton {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  border: 0;
  background: transparent;
}

.mainMenuListItem {
  &:not(:last-child) {
    border-bottom: 1px solid var(--divider-color);
  }
}

.backIcon {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  margin-right: 0.625rem;
  color: var(--label-text-color);
}

.mobileLink {
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.6rem;
  margin: -0.6rem;
}

.mainLink {
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  margin: 0;

  @include tablet-portrait-and-up {
    width: 100%;
  }
}

.linkLogo {
  display: flex;
  align-self: center;
  margin-right: 1rem;
}

.linkName {
  @include ellipsis;
}
