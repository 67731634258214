@use "mixins/media-queries" as *;
@use "mixins/max-root-width";
@use "typography/font" as *;
@use "typography/text" as *;
@use "typography/title-bold" as *;
@use "typography/title-bold-druk" as *;
@use "typography/title-medium" as *;
@use "typography/title-regular" as *;
@use "reset" as *;
@use "typography/label" as *;
@use "grid" as *;

/*
Changing color variables require update of the Colors storybook story
 */
:root {
  // system colors (intact in dark mode)
  --system-yellow: #faff03;
  --hype-green: #e1ff57;

  // shades of gray (intact in dark mode)
  --white: #fff;
  --gray-0: #f5f5fa;
  --gray-0-rgb: 245, 245, 250;
  --gray-1: #e1e0e7;
  --gray-2: #c4c2d0;
  --gray-3: #a19db3;
  --gray-4: #6d6a7f;
  --gray-5: #403e4a;
  --gray-6: #2b2b2b;
  --gray-7: #f3f4f5;
  --black-0: #1a1a1a;
  --black-0-rgb: 26, 26, 26;
  --black: #000;

  // Custom properties used in multiple places:
  --shadow-effect: 0 0.3125em 0.9375em rgba(0, 0, 0, 0.08);
  --active-element-highlight-shadow-filter: drop-shadow(var(--shadow-effect));

  // Z-indexes:
  --header-z-index: 100;
  --page-controls-z-index: 100;
  --promotion-banner-z-index: 100;
  --header-v3-z-index: 101;
  --modal-z-index: 102;    // should be a separate value, because it can be changed only for modal
  --layout-progress-bar-index: 102;
  --article-header-z-index: 20;
  --footer-z-index: 21;  // This z-index avoids our footer to be hidden by the takeover ad
  --embedded-video-player-index: calc(var(--footer-z-index) + 1);
  --entity-title-follow-button-z-index: 1;

  --rounded-corner: 0.25rem;

  --interactive-element-min-size: 44px; // a11y requirement

  --interactive-element-size: 2.75em; //44px but responsive

  --min-distance-between-interactive-elements: 9px; // a11y requirement (more than 8px)

  // Font stacks
  --system-font-stack: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
  "Segoe UI Symbol";
  --druk-font-stack: Druk, var(--system-font-stack);

  // Header height
  --header-height: 3.75rem;

  @include tablet-landscape-and-up {
    --header-height: 5rem;
  }

  --root-side-padding: 1.25rem;
  --root-max-width: calc((100vw - 2 * var(--root-side-padding)));

  @include tablet-portrait-and-up {
    --root-side-padding: 2.5rem;
  }

  @include desktop-and-up {
    --root-max-width: calc((100% - 2 * var(--root-side-padding)));
  }

  @include media-up($breakpoint-desktop + 2.5rem * 2) {
    --root-side-padding: calc((100% - #{$breakpoint-desktop}) / 2);
  }

  --page-controls-control-height: 3.75em;
  --page-controls-padding-bottom: env(safe-area-inset-bottom);
  --page-controls-height: calc(
          var(--page-controls-control-height) + var(--page-controls-padding-bottom)
  );
}

// common colors (updated in dark mode)
@include light-mode {
  // brand colors
  --brand-blue: #2a45f0;
  --brand-magenta: #d81664;
  --brand-orange: #cc3e00;
  --brand-purple: #9600ff;

  // system colors
  --system-red: #ec0a33;
  --system-green: #007846;

  --accent-color: var(--brand-blue);
  --active-element-highlight-color: var(--black-0);
  --alert-background-color: var(--white);
  --divider-color: var(--gray-1);
  --elevated-background-color: var(--gray-0);
  --primary-text-color: var(--black-0);
  --interaction-unactive-state-color: var(--gray-3);
  --label-text-color: var(--gray-4);
  --primary-background-color: var(--white);
  --primary-text-color: var(--black-0);
  --secondary-text-color: var(--gray-5);
  --button-border-color-outline: var(--divider-color);
  --background-reverse-color: var(--black-0);
  --headline-text-color: var(--gray-7);
}

@include dark-mode {
  // brand colors
  --brand-blue: #4e95ff;
  --brand-magenta: #ff5894;
  --brand-orange: #ff7800;
  --brand-purple: #c46fff;

  // system colors
  --system-red: #ff2f54;
  --system-green: #1dbb79;

  --accent-color: var(--hype-green);
  --active-element-highlight-color: var(--hype-green);
  --alert-background-color: var(--gray-6);
  --divider-color: var(--gray-6);
  --elevated-background-color: var(--black-0);
  --primary-text-color: var(--gray-7);
  --interaction-unactive-state-color: #b8b8b8;
  --label-text-color: #f0f0f0;
  --primary-background-color: black;
  --primary-text-color: #f3f4f5;
  --secondary-text-color: white;

  --active-element-highlight-shadow-filter: drop-shadow(
                  0 0.3125em 1.25em rgba(224, 250, 82, 0.15)
  );
  --button-border-color-outline: var(--primary-text-color);
  --shadow-effect: 0 0.3125em 1.25em rgba(224, 250, 82, 0.15);
  --background-reverse-color: var(--gray-0);
  --headline-text-color: #1a1a24;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: var(--system-font-stack);

  background-color: var(--primary-background-color);
  color: var(--primary-text-color);
}

input,
textarea,
select,
button {
  font-size: inherit;
  font-family: inherit;
}

.screen-reader-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.label {
  @include label;
}

.max-root-width {
  @include max-root-width.limit();
}

// We provide a preference center link to open the sdk's
// options from the footer. The button coming from OneTrust's
// SDK overlaps with our navigation bar in mobile screen sizes.
#ot-sdk-btn-floating {
  display: none;
}

[role="button"] {
  cursor: pointer;
}

/*
Resets transform property for all children so that
nothing appears on top of a fullscreen video in Safari.
Safari has a bug with `transform: perspective rotateX`.
 */
body.safari-fullscreen-video-layers-fix * {
  transform: none;
}
