.loading-progress-bar,
.loading-progress-bar:before {
  height: 0.25rem;
  width: 100%;
  margin: 0;
}

.loading-progress-bar {
  display: flex;
  background-color: var(--divider-color);
}

.loading-progress-bar:before {
  content: "";
  animation: running-progress 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  background-color: var(--active-element-highlight-color);
}

@keyframes running-progress {
  0% {
    margin-left: 0;
    margin-right: 100%;
  }
  50% {
    margin-left: 25%;
    margin-right: 0;
  }
  100% {
    margin-left: 100%;
    margin-right: 0;
  }
}
