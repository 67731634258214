@use "@/styles/mixins/ellipsis" as *;
@use "@/styles/mixins/interactive-element-size" as *;
@use "@/styles/mixins/media-queries" as *;
@use "@/styles/mixins/max-root-width";

.footer {
  background-color: var(--elevated-background-color);
  border-top: 1px solid var(--divider-color);
  z-index: var(--footer-z-index);
  position: relative;
}

.wrapper {
  @include max-root-width.limit;
  display: flex;
  flex-direction: column;
  padding-top: 2.5rem;
}

.yahooWrapper {
  @include max-root-width.limit;
  text-align: center;
  justify-content: center;
  display: flex;
  padding: 1.5em 0;
}

.yahooContent {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  max-width: 25rem;
  gap: 1rem;
}

.yahooText {
  color: var(--label-text-color);
}

.yahooSeparator {
  margin-top: 2.5rem;
}

.navLinksWrapper {
  margin-top: 2.5rem;
}

.footerNavs {
  margin-top: 2em;
  display: grid;
  gap: 2.5em 1em;
  padding-bottom: 2rem;

  @include mobile-only {
    grid-template-columns: 50% 50%;
  }

  @include tablet-portrait-only {
    grid-template-columns: repeat(3, 1fr);
  }

  @include tablet-landscape-and-up {
    grid-template-columns: repeat(5, 1fr);
  }
}

.footerNavHeaderText {
  @include ellipsis;
  text-transform: uppercase;
}

.footerNavList {
  margin: 1em 0 0 0;
  padding: 0;
  display: grid;
  gap: var(--min-distance-between-interactive-elements);
  list-style: none;
}

.footerNavLink {
  @include interactive-element-size;
  display: inline-grid;
  align-items: center;
  transition: color 250ms ease-in-out;
  color: inherit;
  text-decoration: none;
  @include ellipsis;
}

@media (hover: hover) {
  .footerNavLink:hover {
    color: var(--accent-color);
  }
}

.footerNavLinkText {
  @include ellipsis;
  color: inherit;
}
